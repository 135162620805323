/* App.css */
@import "bootstrap-icons/font/bootstrap-icons.css";

/* Ensure the body takes full height */
body, html {
  height: 100%;
  margin: 0;
}

/* Flex container for the layout */
.d-flex {
  display: flex;
  height: 100vh; /* Make sure it takes full height */
  flex-direction: row; /* Sidebar and content side by side */
  width: auto;
}

/* Navbar */
.navbar {
  width: 100vw;
  position: fixed; /* Keep it at the top */
  top: 0;
  z-index: 1000;
}

/* Sidebar styling */
.sidebar {
  width: 7rem; /* Default expanded width */
  background-color: #343a40;
  padding-top: 1rem;
  position: fixed;
  top: 3.5rem; /* Adjust based on the height of your navbar */
  bottom: 0;
  height: calc(100vh - 56px); /* Sidebar height minus the navbar height */
  z-index: 500;
  transition: width 0.3s ease, background-color 0.3s ease;
  overflow: hidden;
}

.sidebar.collapsed {
  width: 4rem; /* Collapsed width */
}

.sidebar .nav-link {
  color: white;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem; /* Space between icon and text */
  white-space: nowrap;
  overflow: hidden;
  transition: padding-left 0.3s ease, opacity 0.3s ease;
}

.sidebar.collapsed .nav-link {
  justify-content: center;
  padding: 0.5rem;
}

.sidebar .nav-link i {
  font-size: 1.25rem; /* Icon size */
}

.sidebar .nav-link:hover {
  background-color: #495057;
  text-decoration: none;
}

/* Toggle button */
.toggle-btn {
  position: absolute;
  top: 10px;
  right: -12px;
  background-color: #343a40;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.toggle-btn:hover {
  background-color: #495057;
}

/* Main content */
.main-content {
  margin-left: 15rem; /* Default expanded sidebar width */
  padding: 20px;
  margin-top: 56px; /* Ensure content starts below the navbar */
  transition: margin-left 0.3s ease;
  overflow: hidden;
}

.sidebar.collapsed ~ .main-content {
  margin-left: 4rem; /* Adjust to collapsed sidebar width */
}