/* Ensure the container takes full viewport size and centers content */
.home-container {
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
  height: 100vh; /* Full viewport height */
  width: 75vw; /* Full viewport width */
  margin: 10rem,; /* Remove any margin from body or parent elements */
  box-sizing: border-box; /* Include padding/border in size */
}

/* Wrapper for content (text and buttons) */
.content-wrapper {
  display: flex;
  flex-direction: column; /* Stack text and buttons vertically */
  align-items: center; /* Center-align content horizontally */
  text-align: center;
}

/* Styling for the welcome text */
.welcome-text {
  margin-bottom: 20px;
}

/* Styling for button group */
.button-group {
  display: flex; /* Align buttons side by side */
  gap: 20px; /* Adds spacing between buttons */
  justify-content: center; /* Ensure buttons are horizontally centered */
}

/* Button styling */
.submit-button {
  width: 12rem; /* Set button width */
  height: 4rem; /* Set button height */
  font-size: 1rem; /* Ensure readable text */
}
