/* General container styling */
.capture-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background-color: #f5f5f5;
  border-radius: 0.5rem;
  margin: 0; /* Remove top margin */
  box-shadow: 0 0.25rem 0.625rem rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  width: 100%; /* Take up full width of the screen */
  max-width: 100vw; /* Ensure container doesn’t exceed viewport width */
  height: 100vh; /* Take up full height of the screen */
  overflow: hidden; /* Prevent overflow */
}

/* Card styling */
.capture-card {
  width: 100%; /* Full width of the container */
  max-width: 60rem; /* Constrain width for large screens */
  padding: 2rem;
  background-color: #fff;
  border: 0.0625rem solid #e0e0e0;
  border-radius: 0.625rem;
  box-shadow: 0 0.25rem 0.9375rem rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  overflow: hidden; /* Prevent overflow */
  height: 100vh; /* Take up full height of the container */
}


/* Title styling */
.capture-title {
  text-align: center;
  margin-bottom: 1.25rem;
  color: #333;
  font-size: 1.5rem;
  font-weight: 600;
}

/* Image preview styling */
.image-preview {
  display: flex;
  justify-content: center;
  margin: 1.25rem 0;
}

.captured-image {
  max-width: 100%;
  height: 30%;
  border: 0.0625rem solid #dcdcdc;
  border-radius: 0.5rem;
}

/* OCR Result styling */
.ocr-result {
  margin: 1.35rem 0;
}

.ocr-result h3 {
  color: #333;
  font-size: 1.25rem;
}

.ocr-result p {
  color: #555;
  font-size: 1.3rem;
}

/* Form inputs styling */
.form-group {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1.25rem;
  width: 100%;
}

.form-input {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
}

/* Button styling */
.submit-group {
  display: flex;
  justify-content: center;
}

.submit-button {
  background-color: #ee384e;
  color: white;
  border-radius: 0.5rem;
  padding: 0.75rem;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  width: 100%;
  max-width: 12.5rem;
}

.submit-button:hover {
  background-color: #d43747;
}

/* Success message styling */
.success-message {
  color: green;
  text-align: center;
  margin-top: 0.625rem;
  font-size: 1rem;
}

/* Mobile-specific adjustments */
@media (max-width: 600px) {
  .capture-card {
    padding: 1.5rem;
    max-width: 95vw; /* Ensure card stays within viewport width */
    height: auto; /* Allow card height to adjust for content */
  }

  .capture-title {
      font-size: 1.15rem;
  }

  .form-input,
  .submit-button {
      font-size: 0.9rem;
      padding: 0.5rem;
  }
}

/* OCR action buttons */
/* For scaling with viewport width */
.ocr-action-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  background-color: #ee384e;
  color: white;
  font-size: clamp(0.8rem, 2vw, 1.2rem); /* Scales between 0.8rem and 1.2rem */
  font-weight: 500;
  transition: background-color 0.3s ease, transform 0.2s ease;
  cursor: pointer;
  border: none;
  margin-right: 0.5rem;
}





.ocr-action-button:hover {
  background-color: #d43747; /* Darker shade for hover effect */
  transform: translateY(-2px); /* Subtle hover lift */
}

.ocr-action-button:active {
  background-color: #b93535; /* Darker active state */
  transform: translateY(0); /* Return to normal position */
}

.ocr-action-button svg {
  color: #fff;
  margin-right: 0.5rem;
}

.ocr-action-button:hover svg {
  color: #fff;
}

/* Edit Button Specific Styles */
.ocr-action-button.edit-button {
  background-color: #4caf50; /* Green for 'Edit' */
}

.ocr-action-button.edit-button:hover {
  background-color: #45a049; /* Slightly darker green */
}

/* Retry Search Button Specific Styles */
.ocr-action-button.retry-search-button {
  background-color: #007bff; /* Blue for 'Retry Search' */
}

.ocr-action-button.retry-search-button:hover {
  background-color: #0056b3; /* Darker blue */
}

/* Media Query Adjustments for Larger Screens */
@media (min-width: 600px) {
  .capture-card {
    padding: 2rem;
    max-width: 30rem; /* Constrain width on larger screens */
  }

  .capture-title {
    font-size: 1.5rem;
  }

  .ocr-action-button {
    font-size: 0.9rem;
    padding: 0.1rem 1rem;
  }

  .ocr-action-button.edit-button {
    background-color: #4caf50; /* Green for 'Edit' */
  }

  .ocr-action-button.retry-search-button {
    background-color: #007bff; /* Blue for 'Retry Search' */
  }
}

/* Scaling Adjustments for Extra Small Screens */
@media (max-width: 375px) {
  .capture-container {
    padding: 1rem;
  }

  .capture-title {
    font-size: 1.2rem;
  }

  .form-input,
  .submit-button {
    font-size: 0.85rem;
    padding: 0.5rem;
  }
}

/* Optional: Override with specific font sizes at certain breakpoints */
@media (max-width: 1000px) {
  .ocr-action-button {
    font-size: 120rem; /* Make font size smaller on medium screens like tablets */
  }
}

@media (max-width: 800px) {
  .ocr-action-button {
    font-size: 0.9rem; /* Adjust for smaller screens like phones */
  }
}